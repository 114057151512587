
@import '../helpers/_placeholders.scss';
@import '../helpers/_mixins.scss';
@import '../helpers/_variables.scss';

.sectionHeader, .sectionHeaderAwards{
	font-family:Roboto;
	font-weight:500;
	font-size: 2rem;
	color:$resumeblack;
	border-bottom: $resumeblue solid 0.2em;
	letter-spacing: 0.1em;
}

.sectionHeaderAwards{
	margin-bottom:0;
}


.sectionHeaderFun{
	padding-left:1rem;
	font-variant:small-caps;
	font-family:Helvetica;
	font-weight:500;
	font-size: 1.6em;
	color:$resumefunwhite;
	border-bottom: $resumefunorange solid 0.2em;
	letter-spacing: 0.1em;
}
