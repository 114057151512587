@import '../helpers/_placeholders.scss';
@import '../helpers/_mixins.scss';
@import '../helpers/_variables.scss';

.otherSkillContainer{
	list-style:none;
	font-family:Roboto;
	div{
		&:not(:first-child){
			margin-left: 0.7em;
		}
	}
	.skillList{
		.additionalSkillsDesign{
			display:flex;
			flex-flow:row nowrap;
			.additionalSkillsDesignBox{
				margin:0;
				background:$resumeblue;
				width:4%;
				margin:0.3em 0;
			}
		}
	}
	p{
		font-size:1rem;
		font-weight:lighter;
		color:$resumedarkgrey;
		letter-spacing: 0.03rem;
	}
	h3{
		font-weight:bold;
		font-size: 1.1rem;
		letter-spacing:0.03rem;
		color:$resumeblack;
	}
}

.otherSkillContainerFun{
	list-style:none;
	font-family:Helvetica;
	.category{
		width:20%;
		height:20%;
		background:yellow;
	}
	.circle1{
		display:inline;
		background:orange;
		width:4%;
		height:4%;
	}
	p{
		font-size:0.7em;
		font-weight:lighter;
		color:$resumefunwhite;

	}h3{
		letter-spacing: 0.1em;
    font-weight: bold;
		font-size: 0.8rem;
		color:$resumefungreen;
	}
}

.otherSkillContainer,.otherSkillContainerFun{

	.skillList,.skillListFun{
		p{
			margin:0;
			margin-bottom:1.4%;
			margin-left: 1.3em;
		}
		h3{
			margin:0.2em;
		}
	}
}
