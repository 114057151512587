
@import '../helpers/_placeholders.scss';
@import '../helpers/_mixins.scss';
@import '../helpers/_variables.scss';

.mainSubHeadingContainerFun{
	h3{
		color:$paletteorange;
	}
	h4{
		color:$resumefungreen;
	}
	@media all and (max-width:750px){
		h3{
			font-size:1rem;
		}
		h4{
			font-size:1rem;
		}
	}
}

.mainSubHeadingContainer{
	padding:0.2rem 0;
	@media print{
		page-break-inside: avoid;
		h3{
			orphans:0;
			widows:0;
		}
		h4{

		}
	}
	display:flex;
	flex-flow:row nowrap;
	font-family:Roboto;
	h3{
		font-size:1.5rem;
	}
	h4{
		font-size:1.3rem;
	}
	.designBlock{
		background:$resumeblue;
		 width:4%;
		 margin:0.4rem 0;
		 @media print{
			page-break-after: avoid
		}
	}
	.designBlockHeaders{
		order:2;
		width:95%;
		display: flex;
		flex-flow:column nowrap;
		h3{
			background:orange;
		}
	}

}


.mainSubHeadingContainer, .mainSubHeadingContainerFun{
	h3{
		margin:0.2em;

	}
	h4{
		font-weight:lighter;
		margin:0.2em;
		margin-top:0em;

	}

}
