@import '../helpers/_placeholders.scss';
@import '../helpers/_mixins.scss';
@import '../helpers/_variables.scss';

.PeriodLocationContainerFun{
	font-family:Helvetica;
	h3{
		color:$palettewhite;
		font-weight:bold;
	}
	@media all and (max-width:750px){
		font-size:0.8rem;
		letter-spacing:0.05rem;
		font-weight:normal;
	}
}

.PeriodLocationContainer{
	h3{
		color:$resumedarkgrey;
		font-weight: bold;
	}
}

.PeriodLocationContainer{
	@media print{
		page-break-after: avoid;
 }
	display: flex;
	font-family:Roboto;
	justify-content:space-between;
	margin-top:0.6em;
	h3{
		font-size:1rem;
		margin:0.3em;
		margin-left:0;
		margin-bottom:0;
	}
}
