
$palettewhite: #FFFFFF;
$paletteblue: #001A2E;
$palettered: #C9143B;
$palettegrey: #7B96A9;
$paletteorange: #FF9800;



/*******Professional Resume ColorPalette **********/
$resumeblack: #212121;
$resumeblue: #3186f1;
$resumewhite: #FFFFFF;
$resumelightgrey: #F3F3F3;
$resumedarkgrey: #56555A;



/*******Fun Resume ColorPalette **********/
$resumefunblue: #202C3A;
$resumefunorange:#FF604B;
$resumefunwhite:#FFFFFF;
$resumefungreen: #03A694;
$resumefunlightgrey:#E5E5E5;
$resumefunpink:#fcc0c0;
