@import '../helpers/_placeholders.scss';
@import '../helpers/_mixins.scss';
@import '../helpers/_variables.scss';

.puzzleContainer{
	width:96%;
	height:13%;
	margin:0;
	margin-right:2%;
	margin-left:2%;
	display:flex;
	flex-flow:row nowrap;
	margin-bottom:0.5%;
	@media all and (max-width:750px){
		height: 13%;
	}
	.headPuzzle{
		background:$paletteorange;
		border:solid white 0.1rem ;
		width:25%;
		margin:0;
		z-index:2;
		position: relative;
		.headCircle{
			position: absolute;
			left: 99%;
			top: 37%;
			background:$paletteorange;
			border:solid white 0.1rem ;
			border-left: solid $paletteorange 0.1rem;
			width:18%;
			height:38%;
			border-radius:0 2em 2em 0;

		}
		.headPuzzleContent{
			margin-top:10%;
			margin-bottom:10%;
			margin-left:20%;
			font-size:0.9em;
			color:$paletteblue;
			word-wrap: break-word;
			@media all and (max-width:750px){
					font-size:0.75rem;
			}
		}
	}
	.bodyPuzzle{

		background:$paletteblue;
		border:solid white 0.1rem ;
		margin-left:0.25rem;
		width:65%;
		z-index:1;
		display:flex;
		flex-flow:row wrap;
		position:relative;
		.bodyCircle1{
			position:absolute;
			border:solid white 0.1rem ;
			border-left: solid $resumeblack 0.2rem;
			width:6%;
			height:49%;
			border-radius:0 2em 2em 0;
			top:32%;
			left:-0.1em;
		}
		.bodyPuzzleContent{
			position:absolute;
			top:20%;
			margin-left:13%;
			text-align:justify;

		}
	}
}
