
@import '../helpers/_placeholders.scss';
@import '../helpers/_mixins.scss';
@import '../helpers/_variables.scss';



.updateMainResumeContainer{
	background:$palettewhite;
	@media print{
		margin:0;
	}
}

.updateMainResumeContainerFun{
	background:$paletteblue;

}
