@import '../helpers/_placeholders.scss';
@import '../helpers/_mixins.scss';
@import '../helpers/_variables.scss';

.helloWorld{
	background:transparent;
	color:purple;
}


.helloWorld> h1 {
	font-size:5rem;
  overflow: hidden; /* Ensures the content is not revealed until the animation */
  border-right: .15em solid orange; /* The typwriter cursor */
	border-left: .15em solid transparent;
	white-space: nowrap; /* Keeps the content on a single line */
  margin: 0 auto; /* Gives that scrolling effect as the typing happens */
  letter-spacing: .05em; /* Adjust as needed */
	@media all and (max-width:900px){
		font-size:3.5rem;
	}
	opacity:0;
  /*animation:
    typing 3.5s steps(30, end),
    blink-caret .75s step-end infinite;*/
}

/* The typing effect */
@keyframes typing {
  from { width: 0 }
  to { width: 100% }
}

/* The typewriter cursor effect */
@keyframes blink-caret {
  from, to { border-right-color: transparent }
  50% { border-right-color: orange; }
}
