.educationContainer,.educationContainerFun{
	.innerEducationContainer{
		margin-left:0.7rem;
	}
}
.dummyContainer{
	margin:0;
	padding:0;
}

.educationContainerFun{
	width:100%;
	display:flex;
	flex-flow:row wrap;
	justify-content: space-between;
	font-family:Helvetica;
	letter-spacing:0.05rem;
	@media all and (max-width:750px){
		@media screen and (orientation:landscape){
			height:100vh;
		}
		@media screen and (orientation:potrait){
			height:50vh;
		}
	}
	.sectionEducation{
		min-width:100%;
		flex:1;
		order:1;
		align-self:flex-start;
	}
	.graduationSVGEducation{
		margin:10% 0;
		max-width:50%;
		text-align:right;
		align-self:center;
		svg{
			width:80%;
			fill:white;
			@media screen and (orientation:landscape){
				width:60%;
			}
		}
		order:2;
	}
	.graduationDetail{
		flex:1;
		margin:10% 0;
		text-align: left;
		align-self:center;
		margin-right:0.5rem;
		order:2;
	}
}
