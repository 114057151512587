.Header{
	display: grid;
	grid-template: 10px 160px / 1fr 1fr;
	@media all and (max-width:750px){
		display: grid;
		grid-template: 10px 280px 1fr / 1fr;
	}
	.headerName {
		grid-area: 2 / 1 / span 1 / span 1;
		padding-left:10%;
		position:relative;
		max-width:100%;
		@media all and (max-width:750px){
			padding:0;
			grid-area:2 /1/span 1 / span 1;
			display:flex;
			text-align: center;
			.profileStatement{
				h3{padding:0 2rem;}
			}
		}
	}
	.headerContact {
		grid-area: 2 / 2 / span 1 / span 1;
		padding-right:10%;
		@media all and (max-width:750px){
			padding:0 0;
			grid-area:3/1/span 1 / span 1;
		}
	}
}

/*******Fun Resume ColorPalette **********/
/*$resumefunblue: #202C3A;
$resumefunorange:#FF604B;
$resumefunwhite:#FF604B;
$resumefungrey: #404040;
$resumefunlightgrey:#E5E5E5;*/

.HeaderFun{
	display:flex;
	flex-flow:column nowrap;
	align-items:center;
		.headerName{
			text-align:center;
			flex:1 0 auto;
		}
		.headerContact{
			text-align:center;
			flex: 1 0 auto;
			width: 100%;
			background:transparent;
		}
}
