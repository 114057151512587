
#bigCircle2 {
  //animation: dash 10s linear;
}

#smallCircle1,
#smallCircle2 {
  opacity: 1;
  animation: appear 2s linear
}

#rect1,
#rect2,
#rect3 {
  //animation: rect 3s linear;
}

#smile2,
#smile1 {
  /*animation: smile 5s linear;*/
}

/*@keyframes smile {
  0% {
    stroke-dasharray: 1000;
    stroke-dashoffset: 1050;
  }
  to {
    stroke-dashoffset: 0;
  }
}*/

@keyframes dash {
  0% {
    stroke-dasharray: 1000;
    stroke-dashoffset: 1050;
  }
  to {
    stroke-dashoffset: 0;
  }
}

@keyframes rect {
  0% {
    opacity:0;
  }
  to {
   opacity:1;
  }
}

@keyframes appear {
  0% {
    opacity: 0
  }
  to {
    opacity: 1;
  }
}
