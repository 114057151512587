
.manjeetResume{
	padding-top: 3em;

}
.landing{
	@media print{
		.websiteHeader{
			display:none;
		}
		.manjeetResume{
			margin:0;padding:0;
		}
		button{
			display:none;
		}
	}
}
