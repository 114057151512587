@import '../helpers/_placeholders.scss';
@import '../helpers/_mixins.scss';
@import '../helpers/_variables.scss';



.awardsContainerFun{
	h3{
		color:$resumefungreen;
	}
	li{
		color:$resumefunlightgrey;
	}
}

.awardsContainer{
	h3{
		color:$resumeblack;
	}
	li{
		color:$resumedarkgrey;
	}
}

.awardsContainer,.awardsContainerFun {
	font-family:Roboto;
	display:flex;
	flex-flow: row wrap;
	h3{
		width:100%;
		font-weight:400;
		font-size:0.8em;
		margin: 0;
    margin-bottom: 0.3em
	}
	li{
		flex:1;
		min-width:50%;
		list-style:outside;
		font-size:0.7em;
		font-weight:lighter;
	}
}



@media all and (max-width:600px){
	.awardsContainer {
		flex-flow: column nowrap;
		li{
			width:100%;
		}
	}
}
