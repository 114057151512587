
@import '../helpers/_placeholders.scss';
@import '../helpers/_mixins.scss';
@import '../helpers/_variables.scss';

.descriptionContainerFun{
	font-family:Helvetica;
	border-left:$resumefunwhite dotted 0.1em;
	h3{
		color:$resumefungreen;
		font-weight:500;
		font-size:0.8em;
	}
	ul{
		margin:0;
		margin-top:0.2rem;
		color:$resumefunorange;
		list-style-type: circle;
		list-style-position:outside;
		margin-left: 1.6rem;
		margin-bottom:1.4%;
		padding:0;
	}
	li{
		span{
			font-size:0.7rem;
			color:$resumefunwhite;
			line-height:1rem;
			letter-spacing: 0.1rem;
		}
	}
}

.descriptionContainer{
	/*border-left:$palettered solid 0.1em;
	border-right:$palettered solid 0.1em;*/
	font-family:Roboto;

	h3{
		color:$resumeblue;
		font-weight:bold;
		font-size:1.1rem;
	}
	ul{
		margin:0;
		margin-top:0.2rem;
		font-size:4rem;

		list-style-type: circle;
		list-style-position:outside;
		margin-left: 1.6rem;
		margin-bottom:1.4%;
		padding:0;
	}
	li{
		color:$resumeblue;
		font-size:1.3rem;
		text-align:left;
		padding-bottom:0.5rem;
		span{
			color:$resumedarkgrey;
			font-size:1rem;

		}
	}

}

.descriptionContainer,.descriptionContainerFun{
	ul{
		line-height: 1.3rem;
	}
	h3{
		margin:0em 0em 0em 0.2em;
	}
	li{
		font-weight:lighter;
	}
}
