@import '../helpers/_placeholders.scss';
@import '../helpers/_mixins.scss';
@import '../helpers/_variables.scss';

$break-small:750px;
.section1{
	@include sectionDefault(1);
	background:$paletteblue;
	.part1{
		flex:1;
		align-self: center;
		margin:0 10%;

		@media all and (max-width: $break-small){
			order: 1;
    	min-width: 60%;
    	flex: 1;
			margin:0 10%;
		}
	}
	.part2{
		flex:1;
		align-self: center;
		@media all and (max-width: $break-small){
			flex:1;
			min-width:41%;
			order:2;
			text-align:center;
			align-self: top;
		}
		.mainIntroHeader{
			color:$palettewhite;
			font-size:3.5rem;
			font-weight:30;
			@media all and (max-width: '800px'){
				font-size:3rem;
			}
			@media all and (max-width: $break-small){
				font-size:2.5rem;
				margin-bottom:0.4rem;
			}
		}
		p{
			color:#7B96A9;
			letter-spacing: 0.05rem;
			margin-right:35%;
			@media all and (max-width:'1000px'){
				margin-right:20%;
			}
			@media all and (max-width: $break-small){
				margin:0 10%;
			}
		}
	}
}

.section1Fun{
	@include 		sectionDefault(1);
	background:$palettewhite;
	.part1{
		flex:1;
		align-self: center;
		margin:0 10%;

		@media all and (max-width: $break-small){
			order: 1;
    	min-width: 60%;
    	flex: 1;
			margin:0 10%;
		}
	}
	.part2{
		flex:1;
		align-self: center;

		@media all and (max-width: $break-small){
			flex:1;
			min-width:41%;
			order:2;
			text-align:center;
			align-self: top;
		}
		.mainIntroHeader{
			color:$paletteblue;
			font-size:3.5rem;
			font-family:londrina solid;
			font-weight: bold;
			@media all and (max-width: $break-small){
				font-size:2.5rem;
		}

	}
	p{
		font-family:'Shadows Into Light Two', cursive;
		font-size:1.3rem;
		font-weight: bolder;
		color:$palettered;
		letter-spacing: 0.05rem;
	}
}
}
