@import '../helpers/_placeholders.scss';
@import '../helpers/_mixins.scss';
@import '../helpers/_variables.scss';

.WorkPositionList > *:not(:first-child){
	margin-left:0.7em;
}
.WorkPositionListFun{
	display:flex;
	flex-flow:row wrap;
	align-items:center;
	justify-content: center;
	@media all and (max-width:750px){
		height:100vh;
	}
	h1{
		width:100%;
	}
	.workPositionFun{
		width:100%;
		@media all and (max-width:1150px){
			margin:0;
		}
		.container {
			width: 300px;
			margin-top: 20%;
			margin-left: 30%;
			-webkit-perspective: 1100px;
			-webkit-perspective-origin: -50% -50%;
			@media all and (max-width:1150px){

				margin-left:0;

			}
			@media screen and (max-width:750px){
				margin:0 auto;
				@media screen and (orientation:landscape){
					margin-top: 6.5%;
					margin-left: 30%;
				}
				@media screen and (orientation:portrait){
					margin-top:20%;
				}
			}
		}
		.cube {
			-webkit-transform-style: preserve-3d;
			-webkit-transform: translateZ(-150px);
			position: relative;
			margin: 0;
			width: 300px;
			height: 300px;
			-webkit-transition: 1s;
			@media all and (max-width:1150px){
				margin-left:30%;
			}
			@media all and (max-width:850px){
				margin-left:13%;
			}
			@media all and (max-width:750px){
				width: 250px;
				height: 250px;
			}
		}

		.front, .left, .right, .top, .bottom, .back{
			@media all and (max-width:750px){
				width: 248px;
				height: 248px;
			}
			width: 298px;
			height: 298px;
			position: absolute;
			border: 0.2em solid $paletteorange;
			text-align: center;
			background:$paletteblue;
			font-family:Helvetica;
			display:flex;
			flex-flow:column wrap;
			align-items:flex-start;

		.workPositionCubeDiv{
			margin:0 0.5rem;
			.cubeDate{
				font-size:0.9rem;
				padding:0;
				margin:0;
				color:white;
				padding-top:0.5rem;
				letter-spacing:0.05rem;
				@media all and (max-width:750px){
					padding-top:0.3rem;
					font-size:0.7rem;
				}
			}
			h1{
				color:white;
				letter-spacing:0.1rem;
				font-variant:small-caps;
				margin:0;
				padding:0;
				font-size:1rem;
				padding:0.3rem 0;
				color:$paletteorange;
				@media all and (max-width:750px){
					padding:0.2rem 0;
					font-size:1rem;
				}
			}
			h2{
				color:white;
				margin:0;
				padding:0;
				font-weight:lighter;
				font-size: 0.8rem;
				letter-spacing: 0.1rem;
				padding-bottom:1rem;
				color:$resumefungreen;
				@media all and (max-width:750px){
					padding-bottom:0.5rem;
					font-size:0.7rem;
				}
			}
			li{
				color:white;
				font-weight:lighter;
				margin:0.4rem 1rem;
				font-size:0.75rem;
				letter-spacing:0.03rem;
				text-align: left;
				@media all and (max-width:750px){
					font-size:0.67rem;
					letter-spacing:0.01rem;
					margin:0.2rem 0;;
				}
			}

		}
	}


		.front {
			-webkit-transform: translateZ(150px);
			@media all and (max-width:750px){
				transform:  translateZ(125px);
			}
		}

		.left {
			-webkit-transform: rotateY(-90deg) translateZ(150px);
			@media all and (max-width:750px){
				transform: rotateY(-90deg) translateZ(125px);
			}
		}

		.right {
			-webkit-transform: rotateY(90deg) translateZ(150px);
			@media all and (max-width:750px){
				transform: rotateY(90deg) translateZ(125px);
			}
		}

		.top {
			-webkit-transform: rotateX(90deg) translateZ(152px);
			@media all and (max-width:750px){
				transform: rotateX(90deg) translateZ(125px);
			}
		}

		.bottom {
			-webkit-transform: rotateX(-90deg) translateZ(150px);
			@media all and (max-width:750px){
				transform: rotateX(-90deg) translateZ(125px);
			}
		}

		.back {
			-webkit-transform: rotateY(180deg) translateZ(150px);
			@media all and (max-width:750px){
				transform: rotateY(180deg)translateZ(125px) ;
			}
		}
	}



	label {
		font-family: Helvetica;
    cursor: pointer;
    border-radius: 10px;
    padding: 7px;
    font-size: 0.75rem;
    font-weight: lighter;
    background: transparent;
    border: solid #FF604B 0.2em;
    color: #FFFFFF;
    text-align: center;
    margin: 0.3rem;
	}

	label:hover {
		background: $palettewhite;
		color:$paletteblue;
		border:solid $palettewhite 0.2em;
	}

	input {
		position: absolute;
		left: -9999px;
	}

	input:checked + label {
		font-weight: bold;
		.cube{transform: translateZ(-150px) rotateY(90deg)}
	}

	input[value="front"]:checked ~  .container .cube {
		-webkit-transform: translateZ(-150px) rotateY(0);
	}
	input[value="left"]:checked ~ .container .cube {
		-webkit-transform: translateZ(-150px) rotateY(90deg);
	}

	input[value="right"]:checked ~ .container .cube {
		-webkit-transform: translateZ(-150px) rotateY(-90deg);
	}

	input[value="top"]:checked ~ .container .cube {
		-webkit-transform: translateZ(-150px) rotateX(-90deg);
	}

	input[value="bottom"]:checked ~ .container .cube {
		-webkit-transform: translateZ(-150px) rotateX(90deg);
	}

	input[value="back"]:checked ~ .container .cube {
		-webkit-transform: translateZ(-150px) rotateY(180deg);
	}

}

.WorkPositionList{

	.inlineData{
		@media Print{
			div{
				display:block;

			}
		}
	}

}
