@import '../helpers/_placeholders.scss';
@import '../helpers/_mixins.scss';
@import '../helpers/_variables.scss';

.section2{
	@include sectionDefault(2);
	background:$palettewhite;
	align-items:center;
	.whoAmIpart1{
		flex:1;
		margin:0 10%;
		@media all and (max-width: 1000px){
			margin:0 5%;
		}
		p{
			letter-spacing: 0.1em;
    	text-align:justify;
    	line-height: 1.5rem;

			@media all and (max-width: 750px){
				text-align: center;
			}
		}
		@media all and (max-width: 750px){
			min-width:70%;
			order:2;
			margin:0 15%;
		}

	}
	.whoAmIpart2{
		margin: 0 auto;

		@media all and (max-width: 750px){
			text-align:center;

		}
	}
}

.section2Fun{
	@include sectionDefault(2);
	background:$paletteblue;
	align-items: center;

	@media all and (max-width: 750px){
		align-items: center;
	}
	.whoAmIpart1{
		flex:3;
		order:2;
		@media all and (max-width: 750px){
			flex:1 0 auto;
			width:100%;
			order:2;
		}

	}
	.whoAmIpart2{
		flex:1;
		order:1;
		margin-left:10%;
		p{
			font-family:Helvetica;
			color:$palettewhite;
			letter-spacing:0.1rem;
			font-size:1.7rem;
			text-align:center;
		}
		@media all and (max-width: 750px){
			flex:1 0 auto;
			width:100%;
			order:1;
			margin-left:0%;
		}
	}
}
