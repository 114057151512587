@import '../helpers/_placeholders.scss';
@import '../helpers/_mixins.scss';
@import '../helpers/_variables.scss';
@import './_profileName.scss';
@import './_headerLayout.scss';
@import './_profileContact.scss';



.Header{
	background:$resumedarkgrey;
		padding-bottom:2rem;
	@media all and (max-width:750px){
		padding-bottom:0;
	}
}
.HeaderFun{
	background:transparent;
	.headerName{
		color:white;
			h3,p{
				font-family:Helvetica;
				font-size:1.0rem;
				font-weight:lighter;
				letter-spacing:0.2rem;
				text-align:justify;
				margin:4% 20%;

				@media all and (max-width:750px){
					font-size:0.75rem;
					margin:10% 10%;
				}
			}
			.profilePic{
				border:solid $paletteorange 4px;
				border-radius:1rem;
			}

	}
	.headerContact{
		.profileContactFun{
			div{
				&:hover{
					border-bottom:solid 0.1rem white;
				}
			}
		}
	}
}


.profile{
	color:$resumelightgrey;
	font-family:Roboto;
	.profileName{
		h2{
			letter-spacing:0.02rem;
		}

	}
	.profileStatement{
		@media all and (max-width:750px){
			margin-top: 0.8rem;
		}
		h3{
			text-align: justify;
			letter-spacing:0.1rem;
			@media all and (min-width:1000px){
				font-size:90%;
			}
			@media all and (min-width:750px){
				font-size:110%;
			}
			@media all and (max-width:850px){
				font-size:90%;
			}
			@media all and (max-width:750px){
				padding-top: 0.4rem;
				text-align: center;
				font-size:90%;
			}
		}
	}

	.pic {
		flex:1;
		order:1;
		.profilePic {
			position:relative;
		  border-radius: 50%;
			border:solid $resumeblue 0.3rem;
			max-width:80%;
			@media all and (min-width:750px){
				max-width:90%;
			}
			@media all and (min-width:1200px){
				max-width:70%;
			}
			@media all and (min-width:1600px){
				max-width:60%;
			}
			@media all and (min-width:2000px){
				max-width:50%;
			}
		}
	}

}

.profileContact{
	a{color:$palettewhite;
	font-family:Roboto;
	@media all and (min-width:1000px){
		font-size:1rem;
	}
	@media all and (min-width:750px){
		font-size:1rem;
	}
	@media all and (max-width:850px){
		font-size:0.9rem;
	}
	@media all and (max-width:750px){
		color:$resumedarkgrey;
		font-size: 1rem;
    padding: 0.1rem;
	}
	}

	div{
		margin-bottom: 0.7rem;
		font-size:80%;
		&:hover{
			background: $resumeblue;

			svg{
				fill:$resumewhite;
			}
		}
		svg{
			fill:$resumeblue;
		}
		@media all and (max-width:750px){
			padding-top: 0.4rem;
			background:$resumewhite;

			svg{
					fill:$resumeblue;
			}
		}
	}
}
