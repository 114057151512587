
@import '../helpers/_placeholders.scss';
@import '../helpers/_mixins.scss';
@import '../helpers/_variables.scss';

.skillsContainerFun{
	font-family:Helvetica;
	@media all and (max-width:750px){

		.barContainer{
			padding:2em 0;
		}
	}
	.barContainer{

		h3{
			background:$paletteorange;
			font-weight:bold;
			letter-spacing:0.3rem;
			color:$paletteblue;
			font-size:1rem;
			font-variant:small-caps;
			font-weight:bolder;
			border:solid $palettewhite 0.3em;
		}
		.scale{
			border-top:$palettewhite solid 0.1em;
		}
		.scaleUnit{
			&:first-child{
					border-left:$palettewhite solid 2px;
			}
			border-right:$palettewhite solid 2px;
		}
	}
}

.skillsContainer{
	@media Print{
		display:block;
		page-break-inside: avoid;
	}
	font-family:Roboto;
	.barContainer{
		h3{
			background:$resumedarkgrey;
			font-weight:400;
			color:$palettewhite;
			font-size:1rem;
			border:solid $resumeblue 0.2em;
			font-variant: small-caps;
		}
		.scale{
			border-top:$resumedarkgrey solid 0.1em;
		}
		.scaleUnit{
			&:first-child{
					border-left:$resumedarkgrey solid 2px;
			}
			border-right:$resumedarkgrey solid 2px;
		}
	}
}

.skillsContainer,.skillsContainerFun{

	div{
				margin-left: 0.7em;
				letter-spacing:0.05rem;

		}
	.barContainer{
		margin-right:0.7em;
		padding: 2em 1em 2em 0.3em;
		h3{
			margin:0;
			padding: 0.5rem 0.5rem 0.5rem;
		}
		.scale{
			display:flex;
			flex-flow:row nowrap;
			width:100%;
			margin-bottom:0;
		}
		.scaleUnit{
			margin-top:0;
			height:5px;
			width:20%;
		}
		.skill0, .skill1, .skill2, .skill3, .skill4,  .skill5{
			margin-bottom:1em;
		}
	}
}
