@import '../helpers/_placeholders.scss';
@import '../helpers/_mixins.scss';
@import '../helpers/_variables.scss';

/*******Fun Resume ColorPalette **********/
/*$resumefunblue: #202C3A;
$resumefunorange:#FF604B;
$resumefunwhite:#FFFFFF;
$resumefungrey: #404040;
$resumefunlightgrey:#E5E5E5;*/


.projectsContainerFun{
	font-family:Helvetica;
	display:flex;
	flex-flow:row wrap;
	align-content:center;
	@media all and (max-width:750px){
			margin: 0 1rem;
			margin-bottom: 1rem;
	}
	.sectionHeaderFun{
		width:100%;
	}
	@media all and (max-width:750px){
		display:flex;
		flex-flow:column nowrap;
		justify-content: space-around;
	}
	div{
		width:44%;
		margin:2%;
		padding:1rem 0;
		border-radius:0.2rem;
		flex:1 0 auto;
		background:transparent;
		border:$paletteorange solid 0.2em;
		@media all and (max-width:750px){
			 margin: 0% 10% 10% 10%;
			 min-width:80%;

		}

		@media all and (max-width:1000px){
				width:46%;
		}
	}
	div{
		h3{
			margin:0.2em;
			font-weight:500;
			font-size: 0.9rem;
			color: $palettewhite;
			text-align:center;
			letter-spacing: 0.07rem;
		}
		ul{
			display:none;
			list-style-type:disk;
			font-size:1.2rem;
			letter-spacing:0.05rem;
			text-align: justify;
			list-style-position:outside;
			color: $palettewhite;
			margin-left: 0.8rem;
			margin-bottom:1.4%;
			margin: 0 8%;

		}
		li{
			span{
				color: $palettewhite;
				font-size:0.7rem;
				margin-left:0.2rem;
			}

		}
		&:hover{
			h3{
				color:$paletteorange;
			}
			ul{
				border-top:solid $resumefungreen 0.1em;
				padding-top:0.5rem;
				display:block;
			}
		}
	}
}

.projectsContainer{
	font-family:Roboto;
	div{
		.projectDesignContainer{
			display:flex;
			flex-flow:row nowrap;
			.projectDesignContainerBox{
				background:$resumeblue;
				width:4%;
				margin:0.9rem 0 0.5rem 0;
			}
		}
		h3{
			margin:0.2em;
			margin-top: 0.8rem;
			font-weight:bold;
			font-size: 1.17rem;
			color: $resumeblack;
			letter-spacing: 0.03rem;
		}
		ul{
			line-height: 1.3rem;
			list-style-type:circle;
			list-style-position:outside;
			color:$resumeblue;
			margin-left: 0.8rem;
			margin-bottom:1.4%;
		}
		li{
			padding-bottom:0.5rem;
			font-size: 1.3rem;
			line-height:1.3rem;
			span{
			font-weight:lighter;
			font-size:1rem;
			color:$resumedarkgrey;
		}
		}
	}
}
.projectsContainer,.projectsContainerFun{
	div{
		ul{
			padding: 0;
			-webkit-margin-before: 0.1em;
			-webkit-margin-after: 0.2em;
			-webkit-padding-start: 1.3em;
			li{
				margin:0;
				text-align:left;
			}

		}
	}


}
