@import '../helpers/_placeholders.scss';
@import '../helpers/_mixins.scss';
@import '../helpers/_variables.scss';

.stationaryWebsiteHeader{
	z-index: 1000;
	display:flex;
	flex-flow:row nowrap;
	align-content: space-between;
	background:$palettered;
	position:fixed;
	width:100%;
	top:0;
	left:0;
	font-family:Roboto;
	box-shadow: 0.1rem 0.1rem 0.05rem $paletteblue;
	padding:0.5em;
	nav {
		flex:1;
		a{
			color:white;
			text-decoration: none;
			font-variant:small-caps;
			padding:0 1rem 0 0;
			letter-spacing:0.1em;
		}
	}
	.submitButton{
		margin-right:1rem;
		button {
			background: transparent;
			color: white;
			padding: 0.2rem;
			font-family: roboto;
			letter-spacing: 0.1em;
			border: solid white 0.1em;
			font-variant:small-caps;
		}
		button:hover{
			background:$paletteblue;
		}
	}
}
