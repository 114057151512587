.profile {
	display:flex;
	position:absolute;
	max-width:100%;
	flex-flow:row wrap;
	.pic {
		flex:1;
		order:1;
		.profilePic {
			position:relative;
		  border-radius: 50%;
		}
	}
	.profileName {
		margin-left:3%;
		flex:3;
		order:2;
		h1 {
			margin-top:4%;
			font-size:229%;
			margin-bottom:0;
			font-weight: lighter;
		}
		h2{
			margin-top:0;
			font-size:120%;
			font-weight:normal;
		}
	}
	.profileStatement {
		width:100%;
		order:3;
		h3{
			margin-top:0.7em;
			font-weight:lighter;
			text-align:left;
		}
	}
}
@media all and (max-width:800px){
	.profileName{
		text-align:center;
		h1{
			font-size: 200%;
		}
		h2{
			font-size: 100%;
		}
	}
}

@media all and (max-width:750px){
	.profile{
		max-width:100%;
		flex-flow: column wrap;
		justify-content:center;
		align-items: center;
		svg{
			width:30%;
			flex:1 1 auto;
			justify-content: center;
		}
		.profileName{
			 text-align:center;
			 h1,h2{
				 margin: 0 0;
			 }
		}
		.profileStatement{
			margin-top:0;
			h3{

				margin-top:0.7em;
				text-align:center;
			}
		}
	}
}



/*******Fun Resume ColorPalette **********/
/*$resumefunblue: #202C3A;
$resumefunorange:#FF604B;
$resumefunwhite:#FFFFFF;
$resumefungrey: #404040;
$resumefunlightgrey:#E5E5E5;*/

.profileFun{
	display:flex;
	flex-flow:column nowrap;
	align-items:center;
	.pic{
		padding: 1em;
		.profilePic{
			max-width:30%;
		}
	}
	.profileName{
		font-family:Helvetica;
		display:flex;
		flex-flow:column wrap;
		align-items: center;
		max-width:450px;
		#svg8{
			@media all and (max-width:750px){
				margin:2rem 0 0 0;
			}
		}
		h1{
			color:$resumefunwhite;
			font-variant: small-caps;
			font-weight:bold;
			font-size:4rem;
			Letter-spacing:0.1rem;
			margin-top:0rem;
			margin-bottom: 0.7rem;
			@media all and (max-width:750px){
				font-size:2.7rem;
			}
		}
		h2{
			padding:1em 0;
		}
	}

}
