@import '../helpers/_placeholders.scss';
@import '../helpers/_mixins.scss';
@import '../helpers/_variables.scss';

.typedDescription{

	display:flex;
	flex-flow:column nowrap;
	align-items: center;
	.hoverEffect{
		font-family:Roboto;
		width: 40%;
    padding: 0.5rem;
		letter-spacing:0.1rem;
	 	font-size:1rem;
		@media screen and (max-width:'750px'){
			font-size:0.8rem;
		}
	 	font-weight: bold;
	 	text-align:left;
	 	color:$palettewhite;
    background: transparent;
    border: solid 0.2rem $palettered;
    margin-bottom: 2rem;
    border-radius: 0.5rem;
		transition:
    all        1.2s,
    border     0.5s 1.2s;
		span {
			dispay:none;
			font-family:Roboto;
			font-size:0.8rem;
			@media screen and (max-width:'750px'){
				font-size:0.6rem;
			}
			letter-spacing:0.05rem;
    display: inline-block;
    font-weight: lighter;
    opacity: 0;
    transition:
      opacity 0.1s 0.3s;
  	}
		&:hover {
    text-indent: 0;
		text-align:center;
    background: transparent;
    color: $palettewhite;
    width:100%;
    border: 0.3rem solid $paletteorange;
    span {
			display:block;
      opacity: 1;
    }
  }
	}
}
