
.profileContact{
	display:flex;
	align-items: flex-end;
	flex-flow:column nowrap;
	div{
		&:after{padding:0.07em 0.3em;}
	}
	a{
		position:relative;
		text-decoration: none;
		font-size:90%;
		font-weight:lighter;
		line-height:250%;
		letter-spacing:0.1em;
	}
	.profileLinkedIn, .profileLocation, .profileTel, .profileEmail{
		display:flex;
		flex-flow:row nowrap;
		align-items: center;
    justify-content: flex-end;
		
		svg{
			margin-left:0.4rem;
			margin-bottom: 0.4rem;
		}

	}
	@media all and (max-width:750px){
		.profileLinkedIn, .profileLocation, .profileTel, .profileEmail{
			justify-content: center;
			border-bottom: solid 0.1rem black;
			width:100%;
			margin-bottom:0;
			svg{
				margin-left:0.4rem;
			}
		}
	}

}

.profileContactFun{
	display: flex;
	flex-flow: row wrap;
	align-items: center;
	justify-content: space-evenly;
	font-family:Helvetica;
	 margin-bottom:2rem;
	.profileLinkedIn, .profileLocation, .profileEmail, .profileTel{
			display:flex;
			flex-flow:column wrap;
			align-self:center;

			a{
				color:$resumefunwhite;
				text-decoration:none;
				font-size:1rem;
				padding-top:0.4rem;
				letter-spacing: 0.1rem;
				text-align:center;
				order:2;
			}
			svg{
				fill:$paletteorange;
				padding-bottom:0.1rem;
				align-self: center;
				order:1;
			}
	}

	@media all and (max-width:1200px){
		.profileLinkedIn, .profileLocation, .profileEmail, .profileTel{
			flex-flow:row nowrap;
			align-items:center;
			a{
				font-size: 0.8rem;
				padding-top:0;
			}
			svg{
				padding-right:0.5rem;
			}
		}

		.profileEmail{
			 order:1;
			 border-top:solid $resumefunwhite 0.1rem;
			 width:100%;
			  padding:0.3rem 0;
			 justify-content: center;
			 padding-bottom:0.3rem;
		}
		.profileLocation,.profileTel{
			border-top:solid $resumefunwhite 0.1rem;
			border-bottom:solid $resumefunwhite 0.1rem;
			order:2;
			width:49%;
			padding:0.2rem 0;
			justify-content: center;
			 padding:0.3rem 0;
		}
		 .profileLinkedIn{
			 width:100%;
			 padding:0.3rem 0;
			 order:3;
			 justify-content: center;
			 border-bottom:solid $resumefunwhite 0.1em;
		 }

	}
}
