@import '../helpers/_placeholders.scss';
@import '../helpers/_mixins.scss';
@import '../helpers/_variables.scss';

.section3{
	@include sectionDefault(3);
	background:$palettered;
	align-items:center;
	padding-top:10px;

	.myStrengthsPart1{
		flex:2;
		order:2;

		h1{
			color:white;
			font-size:3rem;
			letter-spacing:0.1rem;
			margin-bottom:0;
		}
		p{
			margin-top:0.2rem;
			letter-spacing:0.1rem;
			font-size:1.2rem;
		}
		/*&::after{
			position: absolute;
    	top: 80%;
    	left: -40%;
			width:40%;
			transform: scaleX(-1);
			content:url(../svg/swirlyArrow1.svg) ;
			color:orange;
		}*/
		@media screen and (max-width:'750px'){
			min-width:100%;
			flex:1 0 auto;
			text-align:center;
			@media screen and (orientation:landscape){
				max-height:40px;
				padding-top:0;
			}
			h1{

				margin:0;
				font-size:3rem;
			}
			p{
				display:none;
			}
		}
	}
	.myStrengthsPart2{
		flex:5;
		order:1;
		svg{
			@media screen and (min-width:'1200px'){
					width:50%;
			}
			@media screen and (max-width:'1200px'){
					width:60%;
			}
			@media screen and (max-width:'1000px'){
					width:70%;
			}

		}
		text-align:center;
		@media screen and (max-width:'750px'){
			min-width:100%;
			svg{
				@media screen and (orientation:portrait) {
					width:90%;
				}
				@media screen and (orientation:landscape){
					width:40%;
				}

			}
			flex:1 0 auto;
			order:2;

		}
	}
}

.section3fun{
	@include sectionDefault(3);
	background:$palettewhite;
	align-items:center;
	.myStrengthsPart1{
		flex:2;
		order:2;
		position:relative;
		h1{
			font-family:'Shadows Into Light Two', cursive;
			color:$palettered;
			font-size:4rem;
			letter-spacing:0.1rem;
			margin-bottom:0;
		}
		p{
			color:#001b2f;
			text-align: left;
			margin:0;
			font-size: 1.5rem;
			font-weight:bolder;
			letter-spacing:0.05rem;
		}
		/*&::after{
			position: absolute;
    	top: 80%;
    	right: -40%;
			width:4%;
			content:url(../svg/arrow.svg) ;
			color:orange;
		}*/
		@media screen and (max-width:'750px'){
			min-width:100%;
			flex:1 0 auto;
			text-align:center;
			@media screen and (orientation:landscape){
				max-height:40px;
				padding-top:0;
			}
			h1{
				margin:0;
				font-size:3rem;
			}
			p{
				display:none;

			}
		}
	}
	.myStrengthsPart2{
		flex:5;
		order:1;
		text-align:center;
		svg{
			@media screen and (min-width:'1200px'){
					width:50%;
			}
			@media screen and (max-width:'1200px'){
					width:60%;
			}
			@media screen and (max-width:'1000px'){
					width:70%;
			}
		}
		@media screen and (max-width:'750px'){
			min-width:100%;
			svg{
				@media screen and (orientation:portrait) {
					width:90%;
				}
				@media screen and (orientation:landscape){
					width:40%;
				}
			}
			flex:1 0 auto;
			order:2;
		}
	}
}
