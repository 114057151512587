@import '../helpers/_placeholders.scss';
@import '../helpers/_mixins.scss';
@import '../helpers/_variables.scss';

/*
$resumeblack: #212121;
$resumeblue: #3F51B5;
$resumewhite: #FFFFFF;
$resumelightgrey: #F3F3F3;
$resumedarkgrey: #56555A;

*/

.mainAwardsSectionContainerFun{
	h1{
		margin-bottom:0;
		width:100%;
	}
	display:flex;
	flex-flow: row wrap;
	justify-content: space-around;
	font-family:Helvetica;
	.awardsSectionContainerFun{
		margin-left:0.7rem;
		margin-top:2rem;
		flex:1;
		justify-content: space-around;
		min-width:160px;
		max-width:160px;
		height:160px;
		border-radius:10em;
		background:$paletteorange;
		position:relative;
		margin-bottom:1rem;
		@media all and (max-width:750px){
			min-width:160px;
			height:160px;
		}
		h4{
			padding:0;
			position:absolute;
			margin:30% 12%;
			@media all and (max-width:750px){
				margin:30% 10%;
				font-size: 0.8rem;
			}
			color:$palettewhite;
			z-index:1;
			font-size:0.8rem;
			letter-spacing:0.05rem;
			text-align:center;
			font-weight:400;

		}
		h5{
			margin: 0;
			margin-left: 0.64em;
			margin-top: 0.8em;
			color:$resumefunwhite;
			position:absolute;
			background:$paletteblue;
			width:130px;
			height:130px;

			border:dotted $resumefungreen 0.4rem;
			border-radius:10em;
			@media all and (max-width:750px){
				margin-top:0.5em;
			}
		}
	}
}


.awardsSectionContainer{
	margin-left:0.7rem;
	.sectionHeader{
		h1{
			margin-bottom:0 !important;
		}
	}
	.AwardsSectionDesign{
		display:flex;
		flex-flow:row nowrap;
		align-self:flex-start;
		.AwardsSectionDesignBox{
			flex: 1;
    	max-width: 4%;
    	background: $resumeblue;
			margin:1.7rem 0 0.2rem 0;
		}
		h4{
			padding-left:0.2rem;
			font-size:1.17rem;
			max-width:96%;
		}
	}

	h5{
		color:$resumedarkgrey;

	}
}

.awardsSectionContainer{
	font-family:Roboto;
	h4{
		margin-bottom:0;
		font-size:0.8rem;
		font-weight:bold;
		letter-spacing: 0.03rem;
	}
	h5{
		line-height: 1.3rem;
		font-weight:lighter;
		margin-top:0.1rem;
		font-size:1rem;
		margin-bottom: 0rem;
		text-align: justify;
	}
}
